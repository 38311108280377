import React from "react";
import NewAccordion from "../NewAccordion";
import Styles from "./EndToEnd.module.scss";

const EnterpriseBusiness = (props) => {
  return (
    <>
      <div className={Styles.EndToEndWrap}>
        <section>
          <div className={Styles.EndToEndHeader}>
            <h2>End-to-end implementation and support</h2>
            <p>
              We involve ourselves in all touch-points - from requirement
              gathering, building solutions, to implementation, issue <br />
              tracking, customer support, and resolution. Here’s how it works:
            </p>
          </div>
          <div className={Styles.EndToEndCardWrap}>
            {props.data.map((data, index) => {
              return (
                <div className={Styles.EndToEndCard} key={index}>
                  <h5>{data.header}</h5>
                  <p>{data.desc}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
      <NewAccordion />
    </>
  );
};

export default EnterpriseBusiness;
