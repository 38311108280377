import React from "react";
import Banner from "../../components/Landers/Banner";
import HomeBrands from "../../components/Landers/EnterpriseHomeBrands";
import Footer from "../../components/Landers/Footer";
import GetStarted from "../../components/Landers/GetStarted";
import Header from "../../components/Landers/Header";
import styles from "../../components/Landers/landers.module.scss";
import SEO from "../../components/Landers/SEO";
import Testimonials from "../../components/Landers/Testimonials";
import EnterpriseBusiness from "../../components/Landers/EnterpriseBusiness";
import EndToEnd from "../../components/Landers/EndToEnd";
import DataInfo from "../../components/Data";

const testimonials = [
  {
    name: "Maryam J ",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. 
    The UX is awesome, checks are fast and most importantly all my queries are answered promptly without me having to wait for long. `,
  },
  {
    name: "Mariam M",
    company: "Touchkin eServices Pvt. Ltd.",
    p: `It's the first time we are doing background checks. But still, SpringVerify gave us very good clarity on the process. They helped us in setting up the account quickly and and helped us perform checks without any hassle.`,
  },
  {
    name: "Pushpita G",
    company: "EZOPS Technologies India Pvt. Ltd.",
    p: `I'm already in love with the UX. It's extremely user-friendly and self-explanatory. All you have to do is to add the candidate and it takes care of the rest. Right from running checks to real-time updates, flags, and reminders - SpringVerify does the job like a breeze.`,
  },
];

export default () => (
  <div className={`${styles.Enterprise} ${styles.m0}`}>
    <SEO
      title="Enterprise Grade Background Checks | SpringVerify India"
      desc="With SpringVerify's robust digital verifications, enterprises can hire ahead of the demand signal with ease."
      url="https://in.springverify.com/enterprise/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+Enterprise+SM+image.jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={"Enterprise-ready \nbackground verification \nsolutions"}
        p="Stay ahead of the demand signal by running prompt employee verifications. We seamlessly meet your expanding needs with greater customizability, security and support - without compromising on the experience."
        img="https://assets-sv-in.gumlet.io/LP/enterprise/banner.svg"
      />
    </div>
    <HomeBrands />
    <EnterpriseBusiness data={DataInfo.EnterpriseBusiness} />
    <EndToEnd data={DataInfo.EndToEnd} />
    <Testimonials
      h2={"Trusted by 1000+ \nhappy customers"}
      testimonials={testimonials}
      path="Employment"
    />
    <GetStarted
      img="enterprise/getStarted.svg"
      h3={"Start screening your \ncandidates with \nSpringVerify"}
    />
    <Footer />
  </div>
);
