import React from "react";
import Styles from "./EnterpriseBusiness.module.scss";

const EnterpriseBusiness = (props) => {
  return (
    <>
      <div className={Styles.EnterpriseBusiness}>
        <section>
          <div className={Styles.EBHeader}>
            <h2>
              A platform that understands <br />
              your enterprise business
            </h2>
          </div>
          <div className={Styles.EBCardWrap}>
            {props.data.map((data, index) => {
              return (
                <div className={Styles.EBCard} key={index}>
                  <img src={data.path} />
                  <h5>{data.header}</h5>
                  <p>{data.desc}</p>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default EnterpriseBusiness;
