import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./NewAccordion.module.scss";
import Arrow from "../../../images/LP/enterprise/arrow.png";

export default function App() {
  return (
    <div className={styles.AccordionWrap}>
      <section>
        <div className={styles.AccordionHeader}>
          <h2>End-to-end implementation and support</h2>
          <p>
            We involve ourselves in all touch-points - from requirement
            gathering, building solutions, to implementation, issue tracking,
            customer support, and resolution. Here’s how it works:
          </p>
        </div>
        <Accordion defaultActiveKey="0">
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                <span>Requirement analysis</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                We understand your company landscape and business processes to
                identify challenges, gaps, and solve background verification
                challenges.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="1">
                <span>Build customized solutions</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                Collate insights, and build tailored verification solutions
                based on your business requirements.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="2">
                <span>Assisted onboarding</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
                We help you up-skill your organization by scheduling in-person
                or team-wide training programs with one of our product experts.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="3">
                <span>Effortless data migration</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
                Our dedicated team of experts helps you shift your data from
                existing solution providers ensuring a smooth transition with no
                errors or data loss.
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="4">
                <span>Prototyping & implementation</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
                We align SpringVerify with your existing processes in a precise
                and efficient manner with customized implementation and support.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="5">
                <span>Post-purchase training and support</span>
                <img src={Arrow} />
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
                Our Dedicated Account Manager works in tandem with your company
                to deliver the most personalized experience post-implementation.
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>
    </div>
  );
}
